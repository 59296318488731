import React, { useCallback } from 'react';
import { globalState } from 'shared/foreground/models';
import { DisplayTheme } from 'shared/types';

import styles from './DonutProgressIndicator.module.css';

type Props = {
  className?: string;
  progressPercentage: number;
};

export default function DonutProgressIndicator({ className, progressPercentage }: Props): JSX.Element {
  const backgroundColor = globalState(
    useCallback((state) => (state.client.theme === DisplayTheme.Dark ? '#3E4853' : '#F0F1F2'), []),
  );
  const progressInDegrees = `${Math.round((progressPercentage / 100) * 360)}deg`;
  const wrapperClasses = ['donutProgressIndicatorWrapper', styles.wrapper, className];
  const indicatorClasses = ['donutProgressIndicatorIndicator', styles.indicator];

  return (
    <div className={wrapperClasses.join(' ')}>
      <span className="hideAccessibly">{progressPercentage}%</span>
      <div
        className={indicatorClasses.join(' ')}
        style={{
          background: `conic-gradient(transparent 0deg ${progressInDegrees}, ${backgroundColor} calc(${progressInDegrees} + 1deg) 360deg),
          conic-gradient(#49C8FE 0deg, #8D6CDF 90deg, #BB40AD 180deg, #DE8B7C 220deg 280deg, #D3C500 360deg)`,
        }}
      />
    </div>
  );
}
